// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("inputmask");
require("moment");
require("trix");

import "intl-tel-input/build/css/intlTelInput.min";
require("intl-tel-input/build/js/utils");
window.intlTelInput = require("intl-tel-input");
import "@fortawesome/fontawesome-free/js/all";
import "@yaireo/tagify";
import "powerbi-client";

require("../javascripts/denunciations/admin/new");
require("../javascripts/denunciations/attribution");
require("../javascripts/denunciations/edit");
require("../javascripts/denunciations/denunciation-menu");
require("../javascripts/denunciations/index");
require("../javascripts/denunciations/new");
require("../javascripts/denunciations/case");
require("../javascripts/involved/index");
require("../javascripts/involved/edit");
require("../javascripts/more_informations/more_informations");
require("../javascripts/components/data-confirm-modal");
require("../javascripts/select_option");
require("../javascripts/cookie_policy");
require("../javascripts/action_plan/action_plan_items");
require("../javascripts/report_to_do/report_to_do");
require("../javascripts/blob_partial/blob");
require("../javascripts/pages/bg_img");
require("../javascripts/login");
require("../javascripts/trix_override");
require("../javascripts/dashboard.js");
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

import "bootstrap";

import "../css/application";
