document.addEventListener("turbolinks:load", () => {
  const denunciationsList = document.querySelector("#denunciations-list");
  const selectedDenunciationsListItems = document.querySelectorAll("#selected-denunciations-list > li");
  const searchCaseForm = document.querySelector("#search-case-form");
  const pathParams = window.location.pathname.split("/");
  const tenantPrefix = pathParams[1];
  const tenantName = pathParams[2];
  const basePath = `/${tenantPrefix}/${tenantName}/denunciations/search`;

  if (denunciationsList) {
    fetchAndProcessData(basePath, denunciationsList, tenantName, tenantPrefix);
  }

  if (searchCaseForm) {
    searchCaseForm.addEventListener("submit", (event) => {
      event.preventDefault();
      const formData = new FormData(searchCaseForm);
      const searchParams = new URLSearchParams(formData).toString();
      const searchPath = `${basePath}?${searchParams}`;

      fetchAndProcessData(searchPath, denunciationsList, tenantName, tenantPrefix);
    });
  }

  if (selectedDenunciationsListItems && selectedDenunciationsListItems.length > 0) {
    selectedDenunciationsListItems.forEach((item) => {
      item.addEventListener("click", () => {
        item.classList.toggle("selected");
      });
    });
  }

  setupMoveButton("#add-selected", ".selectable-for-case.selected", "#selected-denunciations-list", ["selected", "selectable-for-case"], ["selected-for-case"]);
  setupMoveButton("#add-all-selected", ".selectable-for-case", "#selected-denunciations-list", ["selected", "selectable-for-case"], ["selected-for-case"]);
  setupMoveButton("#remove-selected", ".selected-for-case.selected", "#denunciations-list", ["selected", "selected-for-case"], ["selectable-for-case"]);
  setupMoveButton("#remove-all-selected", ".selected-for-case", "#denunciations-list", ["selected", "selected-for-case"], ["selectable-for-case"]);
});

function setupMoveButton(buttonSelector, sourceSelector, destinationSelector, removeClasses, addClasses) {
  document.querySelector(buttonSelector).addEventListener("click", () => {
    moveDenunciations(sourceSelector, destinationSelector, removeClasses, addClasses);
  });
}

function buildDenunciationListItem(denunciation, locale, tenantName, tenantPrefix, buttonLabel) {
  const li = document.createElement("li");
  li.classList.add("d-flex", "justify-content-between", "align-items-center", "py-2", "selectable-for-case", "px-3", "my-1");
  li.setAttribute("data-denunciation-id", denunciation.id);
  li.innerHTML = `
    <div class="mr-2">${denunciation.protocol_number}</div>
    <div class="mr-2">${denunciation.aasm_status[locale]}</div>
    <a class="btn btn-small btn-outline-primary" href="/${tenantPrefix}/${tenantName}/denunciations/${denunciation.id}/detail" target="_blank">${buttonLabel}</a>
  `;
  li.addEventListener("click", () => {
    li.classList.toggle("selected");
  });
  return li;
}

async function fetchAndProcessData(path, denunciationsList, tenantName, tenantPrefix) {
  try {
    const response = await fetch(path);
    const data = await response.json();
    denunciationsList.innerHTML = "";
    data.denunciations.forEach((denunciation) => {
      denunciationsList.appendChild(buildDenunciationListItem(denunciation, data.locale, tenantName, tenantPrefix, data.button_label));
    });
  } catch (error) {
    console.error(error);
  }
}

function moveDenunciations(sourceSelector, destinationSelector, removeClasses, addClasses) {
  const sourceElements = document.querySelectorAll(sourceSelector);
  const destinationContainer = document.querySelector(destinationSelector);
  sourceElements.forEach((element) => {
    destinationContainer.appendChild(element);
    removeClasses.forEach((cls) => element.classList.remove(cls));
    addClasses.forEach((cls) => element.classList.add(cls));

    if (destinationSelector === "#denunciations-list") {
      updateDenunciationIds(element, "remove");
    } else {
      updateDenunciationIds(element, "add");
    }
  });
}

function updateDenunciationIds(element, action) {
  const denunciationId = element.getAttribute("data-denunciation-id");
  const hiddenInput = document.querySelector("#denunciation_ids");
  const denunciationIds = hiddenInput.value.split(",").filter(id => id);

  if (action === "add" && !denunciationIds.includes(denunciationId)) {
    denunciationIds.push(denunciationId);
  } else if (action === "remove") {
    const index = denunciationIds.indexOf(denunciationId);
    if (index > -1) {
      denunciationIds.splice(index, 1);
    }
  }

  hiddenInput.value = denunciationIds.join(",");
}
